@tailwind base;
@tailwind components;
@tailwind utilities;

/* html::-webkit-scrollbar {
	width: 0.6em;
	height: 0.6em;
}
html::-webkit-scrollbar-thumb {
	background:  #504CBC ;
	border-radius: 10px;
	box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 25%),
		inset -2px -2px 2px rgba(255, 254, 254, 0.25);
	-webkit-transition: 0.5s ease;
	transition: 0.5s ease;
}
html::-webkit-scrollbar-track {
	background: (255, 255, 255);
} */
/* html {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
html::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
html::-webkit-scrollbar-thumb {
  background: rgb(187, 185, 185);
  border-radius: 15px;
  height: 10px;
} */

* {
  padding: 0;
  margin: 0;
}

html  
 {
  max-width: 100vw;
  overflow-x: hidden;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../public/font/AvenirNextFont.ttf");
  font-weight: regular;
  font-style: regular;
}
@font-face {
  font-family: "Avenir Next Bold";
  src: url("../public/font/AvenirNextBold.ttf");
  font-weight: bold;
  font-style: bold;
}

.hidden-scroll {
  -ms-overflow-style: none !important;
  scrollbar-width: 1px;
  margin-bottom: 5px;
}
.hidden-scroll::-webkit-scrollbar {
  width: 1px;
  height: 8px;
}
.hidden-scroll::-webkit-scrollbar-track {
  background: rgba(22, 163, 74, 0.2);
  border-radius: 15px;
}
.hidden-scroll::-webkit-scrollbar-thumb {
  background: rgb(27, 54, 37);
}
.custom-checkbox {
  position: relative;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.custom-checkbox:checked {
  background-color: #504cbc;
  border-color: #504cbc;
}
.custom-checkbox:checked::before {
  content: "\2713"; /* Checkmark Unicode character */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #fff;
}
.spinner {
  position: relative;
  width: 22.4px;
  height: 22.4px;
}

.spinner::before,
.spinner::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  animation: spinner-b4c8mmmd 0.5s backwards,
    spinner-49opz7md 1.25s 0.5s infinite ease;
  border: 5.6px solid #474bff;
  border-radius: 50%;
  box-shadow: 0 -33.6px 0 -5.6px #474bff;
  position: absolute;
}

.spinner::after {
  animation-delay: 0s, 1.25s;
}

@keyframes spinner-b4c8mmmd {
  from {
    box-shadow: 0 0 0 -5.6px #474bff;
  }
}

@keyframes spinner-49opz7md {
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 320px) {
  .custom-checkbox {
    width: 24px;
    height: 17px;
  }
}
@media (min-width: 450px) {
  .custom-checkbox {
    width: 15px;
    height: 15px;
  }
}

.button.radius-round {
  border-radius: 0.375rem;
}
.button.radius-circle {
  border-radius: 9999px;
}
.button.radius-none {
  border-radius: 0;
}
.date-picker {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.5rem;
  max-width: 20rem;
  padding: 1rem;
  z-index: 40;
}
.dark .date-picker,
.date-picker {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.dark .date-picker {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.date-picker-panel {
  border-radius: 0.5rem;
}
.date-picker-header-label {
  color: rgb(17 24 39 / var(--tw-text-opacity));
  cursor: pointer;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  -webkit-user-select: none;
  user-select: none;
}
.dark .date-picker-header-label,
.date-picker-header-label {
  --tw-text-opacity: 1;
}
.dark .date-picker-header-label {
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.date-picker-current-month {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}
.dark .date-picker-current-month {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.date-picker-other-month {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.dark .date-picker-other-month {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.date-picker-available:hover,
.date-picker-selected-date {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.date-picker-today {
  border-width: 1px;
}
.date-picker-month-cell {
  font-weight: 600;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  text-align: center;
}
.date-picker-month-cell-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
  cursor: not-allowed;
  opacity: 0.3;
}
.date-picker-month-cell-inner {
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  -webkit-user-select: none;
  user-select: none;
}
.date-picker-year-cell {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  text-align: center;
}
.date-picker-year-cell-inner {
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 600;
  padding: 0.5rem 1rem;
  -webkit-user-select: none;
  user-select: none;
}
.date-picker-year-cell-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
  cursor: not-allowed;
  opacity: 0.3;
}
.picker {
  border-radius: 0.5rem;
  z-index: 40;
}
.picker-panel {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.5rem;
  padding: 1rem;
}
.dark .picker-panel,
.picker-panel {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.dark .picker-panel {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.picker-view {
  display: flex;
  gap: 1.5rem;
  min-width: 260px;
}
.picker-table,
.picker-view {
  width: 100%;
}
.picker-table {
  border-collapse: collapse;
}
.picker-header-arrow {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1.125rem;
  height: 2.5rem;
  line-height: 1.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  -webkit-user-select: none;
  user-select: none;
}
[dir="rtl"] .picker-header-arrow {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.picker-header-label {
  color: rgb(17 24 39 / var(--tw-text-opacity));
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  -webkit-user-select: none;
  user-select: none;
}
.dark .picker-header-label,
.picker-header-label {
  --tw-text-opacity: 1;
}
.dark .picker-header-label {
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.month-table,
.year-table {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.date-picker-cell {
  height: 2.25rem;
  padding: 0;
  text-align: center;
  width: 2.25rem;
}
.date-picker-cell-content {
  font-weight: 400;
  height: 100%;
  width: 100%;
}
.date-picker-cell-current-month {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}
.dark .date-picker-cell-current-month {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}
.date-picker-cell-hoverable:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.dark .date-picker-cell-hoverable:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.date-picker-cell-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
  cursor: not-allowed;
  opacity: 0.3;
}
.date-picker-week-cell {
  font-weight: 600;
}
.week-day-cell {
  height: 1.75rem;
}
.day-picker,
.month-picker,
.year-picker {
  width: 100%;
}
.month-picker-cell,
.year-picker-cell {
  border-radius: 0.5rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  text-align: center;
}
.month-picker-cell-disabled,
.year-picker-cell-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
  cursor: not-allowed;
  opacity: 0.3;
}
.input {
  @apply border
  text-black	
  border-gray-300 
  /* dark:bg-transparent  */
  rounded-md 
  w-full 
  py-2 px-3 
  focus:outline-none 
  focus:ring-1 
  focus-within:ring-1 
  appearance-none 
  transition 
  duration-150 
  ease-in-out;
  &::placeholder {
    @apply text-gray-400;
  }

  &.input-invalid {
    @apply ring-1 ring-red-500 border-red-500;
  }

  &.input-lg {
    @apply text-lg;
  }
}

.input-addon {
  @apply flex items-center px-4 rounded-md border border-gray-300 bg-gray-50 dark:border-gray-600 dark:bg-gray-700;
}

.input-textarea {
  min-height: 7rem;
}

.input-wrapper {
  @apply relative w-full flex;
}

.input-disabled {
  @apply bg-gray-100 border-gray-200  text-gray-400 cursor-not-allowed;

  &::placeholder {
    @apply opacity-70;
  }
}
.input-group .date-picker.picker-expanded,
.input-group .input-suffix-end,
.input-group .input-suffix-start,
.input-group .select__control--is-focused {
  z-index: 20;
}
.input-suffix-start {
  @apply absolute top-2/4 transform -translate-y-2/4 left-2.5;
}

.input-suffix-end {
  @apply absolute top-2/4 transform -translate-y-2/4 right-2.5 flex;
}

.notification {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  border-radius: 0.5rem;
  border-width: 1px;
  margin-bottom: 0.75rem;
  position: relative;
}
.dark .notification,
.notification {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.dark .notification {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  border-color: transparent;
}
.notification-content {
  display: flex;
  padding: 1rem;
}
.notification-content.no-child {
  align-items: center;
}
.notification-title {
  font-family: "Avenir Next";
  color: rgba(31, 41, 55, var(--tw-text-opacity));
  font-weight: 500;
}
.dark .notification-title {
  font-family: "Avenir Next, sans-serif";
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.notification-close {
  top: 1.25rem;
}
[dir="ltr"] .notification-close {
  right: 1rem;
}
[dir="rtl"] .notification-close {
  left: 1rem;
}
.time-input-field {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width))
    var(--tw-ring-color);
  background-color: initial;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  outline: 2px solid #0000;
  outline-offset: 2px;
  text-align: center;
  width: 1.5rem;
}
.time-input-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
}
.time-input-separator {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.timeline {
  align-items: flex-start;
  flex-direction: column;
}
.timeline-item {
  display: flex;
  min-height: 70px;
}
.timeline-item-last {
  min-height: max-content;
}
.timeline-item-media {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.timeline-item-media-content {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}
.timeline-item-media-default {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  border-radius: 9999px;
  height: 1rem;
  width: 1rem;
}
.dark .timeline-item-media-default {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.timeline-item-wrapper {
  display: flex;
  flex: 1 1 auto;
}
.timeline-item-content {
  margin-left: 1rem;
  padding-bottom: 1.5rem;
  padding-top: 0.125rem;
  width: 100%;
}
[dir="rtl"] .timeline-item-content {
  margin-right: 1rem;
}
.timeline-item-content-last {
  padding-bottom: 0;
}
.timeline-connect {
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  flex-grow: 1;
  height: 100%;
  width: 2px;
}
.dark .timeline-connect,
.timeline-connect {
  --tw-bg-opacity: 1;
}
.dark .timeline-connect {
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.toast {
  position: fixed;
  z-index: 40;
}
.bg-custom-900 {
  background-color: #504cbc;
}
.text-custom-900 {
  color: #504cbc;
}

.select {
  .select__control--is-disabled {
    @apply bg-gray-100 border-gray-200 text-gray-400  cursor-not-allowed;

    .select__placeholder {
      @apply text-gray-300 ;
    }

    .select__multi-value {
      @apply opacity-60;
    }
  }

  .select__placeholder {
    @apply text-gray-400;
  }

  .select__multi-value {
    @apply bg-[#ABEBEB] text-base rounded-lg font-semibold;

    .select__multi-value__label {
      color: inherit;
    }
  }

  .select__multi-value__remove {
    &:hover {
      @apply bg-transparent text-gray-400 dark:text-gray-400;
    }
  }

  &.select-lg {
    @apply text-lg;

    .select-dropdown-indicator {
      @apply text-2xl;
    }
  }

  .select__single-value--is-disabled {
    @apply opacity-70;
  }

  .select__input {
    input {
      @apply dark:!text-gray-100;
    }
  }

  .select__control {
    @apply h-auto border border-gray-200 focus:outline-2 focus-within:outline-2  focus:border-2  focus:ring-[#333333] focus-within:ring-[#333333] focus-within:border-[#333333];

    &.hide-single-value {
      .select__single-value {
        @apply !hidden;
      }
    }
  }
}

/* .select__menu {
  @apply  ;
} */

.select-option {
  @apply flex items-center justify-between px-2 py-1.5 hover:bg-gray-200 cursor-default;

  &.selected {
    @apply bg-gray-100  ;
  }

  &.disabled {
    @apply opacity-40 dark:opacity-30 hover:bg-transparent cursor-not-allowed;
  }
}

.select-dropdown-indicator {
  @apply p-2 text-xl hover:opacity-70 mr-2;
}

.select-clear-indicator {
  @apply p-2 text-lg hover:opacity-70;
}
.upload {
  @apply relative inline-block;

  &.disabled {
    @apply cursor-not-allowed opacity-60;

    .upload-input {
      @apply cursor-not-allowed;
    }
  }
}

.upload-input {
  @apply absolute inset-0 hidden;

  &.draggable {
    @apply block opacity-0 w-full cursor-pointer;
  }
}

.upload-draggable {
  padding: 0 10px;
  text-align: center;
  min-height: 14rem;

  @apply border-gray-300 dark:border-gray-600 rounded-lg cursor-pointer flex items-center justify-center;
}

.upload-file-list {
  @apply mt-4;
}

.upload-file-info {
  min-height: 4rem;
  @apply flex flex-col justify-center ltr:ml-3 rtl:mr-3;
}

.upload-file {
  @apply flex items-center border border-gray-300 dark:border-gray-600 rounded-lg mb-2 justify-between;
}

.upload-file-thumbnail {
  height: 4rem;
  width: 4rem;
  @apply p-2 flex justify-center items-center;
}

.upload-file-image {
  @apply max-w-full;
}

.upload-file-remove {
  @apply p-3 mx-2;
}
.picker-table,
.picker-view {
  width: 100%;
}
.picker-view {
  display: flex;
  gap: 1.5rem;
  min-width: 260px;
}
.day-picker,
.month-picker,
.year-picker {
  width: 100%;
}
.picker-header-label {
  color: rgb(17 24 39 / var(--tw-text-opacity));
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  -webkit-user-select: none;
  user-select: none;
}

/* button,
select {
  text-transform: none;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
} */

.skeleton {
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  display: flex;
}

.dark .skeleton,
.skeleton {
  --tw-bg-opacity: 1;
}

.dark .skeleton {
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}

.skeleton-circle {
  border-radius: 9999px;
  height: 2.5rem;
  width: 2.5rem;
}

.skeleton-block {
  border-radius: 0.25rem;
  height: 0.625rem;
  width: 100%;
}
.table-default {
  min-width: 100%;
  table-layout: auto;
}

.table-default > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
}

:is(.dark .table-default) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
}

.table-default thead {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.dark .table-default thead {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.table-default thead > tr > th {
  /* color: rgba(107, 114, 128, var(--tw-text-opacity)); */
  color: white;
  /* text-align: center; */
  text-align: left;
  font-size: 0.75rem;
  /* font-weight: 600; */
  letter-spacing: 0.05em;
  line-height: 1rem;
  padding: 0.75rem 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.dark .table-default thead > tr > th,
.table-default thead > tr > th {
  --tw-text-opacity: 1;
}

.dark .table-default thead > tr > th {
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.table-default tbody > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
}

:is(.dark .table-default tbody) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
}

.table-default tbody > tr > td {
  padding: 1rem 1.5rem;
}

.table-default tfoot > tr > td {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border-top-width: 1px;
  padding: 0.75rem 1.5rem;
}

.dark .table-default tfoot > tr > td {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.table-default.borderless-row tbody > :not([hidden]) ~ :not([hidden]) {
  border-style: none;
}

.table-flex {
  min-width: 100%;
}

.table-flex > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
}

:is(.dark .table-flex) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
}

.table-flex .thead {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.dark .table-flex .thead {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.table-flex .thead .tr .th {
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1rem;
  padding: 0.75rem 1.5rem;
  text-align: left;
  text-transform: uppercase;
}

.dark .table-flex .thead .tr .th,
.table-flex .thead .tr .th {
  --tw-text-opacity: 1;
}

.dark .table-flex .thead .tr .th {
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.table-flex .tbody > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
}

:is(.dark .table-flex .tbody) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
}

.table-flex .tbody .tr .td {
  padding: 1rem 1.5rem;
}

.table-flex .tfoot .tr .td {
  border-top-width: 1px;
  padding: 0.75rem 1.5rem;
}

.dark .table-flex .tfoot .tr .td {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.table-virtual > div > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
}

:is(.dark .table-virtual > div) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
}

.table-compact thead > tr > th {
  font-size: 0.75rem;
  line-height: 1rem;
}

.table-compact .thead > .tr > .th,
.table-compact tbody > tr > td,
.table-compact thead > tr > th {
  padding: 0.5rem 0.75rem;
}

.table-compact .thead > .tr > .th {
  font-size: 0.75rem;
  line-height: 1rem;
}

.table-compact .tbody > .tr > .td {
  padding: 0.5rem 0.75rem;
}

.table-hover > tbody > tr:hover > * {
  background-color: rgba(243, 244, 246, 0.5);
}

.dark .table-hover > tbody > tr:hover > * {
  background-color: rgba(55, 65, 81, 0.4);
}

.table-hover > .tbody > .tr:hover > * {
  background-color: rgba(243, 244, 246, 0.5);
}

.dark .table-hover > .tbody > .tr:hover > * {
  background-color: rgba(55, 65, 81, 0.4);
}

.table-resizer {
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border-right-width: 2px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 0.5rem;
  z-index: 10;
}

.dark .table-resizer,
.table-resizer {
  --tw-border-opacity: 1;
}

.dark .table-resizer {
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.table-resizer {
  touch-action: none;
}

td,
th {
  word-wrap: break-word;
}

.tab-list {
  align-items: center;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}
.tab-list-underline {
  border-bottom-width: 2px;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.dark .tab-list-underline,
.tab-list-underline {
  --tw-border-opacity: 1;
}
.dark .tab-list-underline {
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.tab-nav {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: center;
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.tab-nav.tab-nav-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.tab-nav-underline {
  border-bottom-width: 2px;
  border-color: #0000;
  padding: 0.75rem 1.25rem;
}
.tab-nav-pill {
  border-radius: 0.375rem;
  margin-right: 0.25rem;
  padding: 0.5rem 1.25rem;
}
[dir="rtl"] .tab-nav-pill {
  margin-left: 0.25rem;
}
.tab-nav-icon {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
[dir="ltr"] .tab-nav-icon {
  margin-right: 0.375rem;
}
[dir="rtl"] .tab-nav-icon {
  margin-left: 0.375rem;
}
.tab-nav-active {
  border-bottom: 2px solid #504cbc;
}

.picker-table {
  border-collapse: collapse;
}
.picker-table,
.picker-view {
  width: 100%;
}
.week-day-cell {
  height: 1.75rem;
}
td,
th {
  word-wrap: break-word;
}
.date-picker-week-cell {
  font-weight: 600;
}
.date-picker-cell {
  height: 2.25rem;
  padding: 0;
  text-align: center;
  width: 2.25rem;
}
.date-picker-cell-hoverable:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.date-picker-other-month {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.date-picker-other-month {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.date-picker-cell-content {
  font-weight: 400;
  height: 100%;
  width: 100%;
}
.month-picker-cell,
.year-picker-cell {
  border-radius: 0.5rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  text-align: center;
}
.month-table,
.year-table {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.date-picker-cell-disabled {
  --tw-bg-opacity: 1;
  cursor: not-allowed;
  opacity: 0.3;
}
*,
::backdrop,
:after,
:before {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
*,
:after,
:before {
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
}
.inputStyle {
  width: 2rem !important;
  height: 2rem;
  margin: 0 0.4rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid #808080;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tag {
  align-items: center;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px;
  display: inline-flex;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  padding: 0.25rem 0.625rem;
  white-space: nowrap;
}
.dark .tag,
.tag {
  --tw-border-opacity: 1;
}
.dark .tag {
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.tag-affix {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  border-radius: 9999px;
  height: 0.5rem;
  width: 0.5rem;
}
.tag-prefix {
  margin-right: 0.375rem;
}
.tag-suffix,
[dir="rtl"] .tag-prefix {
  margin-left: 0.375rem;
}
[dir="rtl"] .tag-suffix {
  margin-right: 0.375rem;
}

.progress {
  display: inline-block;
}
.progress.circle,
.progress.line {
  position: relative;
  width: 100%;
}
.progress.line {
  align-items: center;
  display: flex;
}
.progress-bg {
  border-radius: 9999px;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
[dir="ltr"] .progress-info.line {
  margin-left: 0.5rem;
}
[dir="rtl"] .progress-info.line {
  margin-right: 0.5rem;
}
.progress-inner {
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  border-radius: 9999px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.dark .progress-inner,
.progress-inner {
  --tw-bg-opacity: 1;
}
.dark .progress-inner {
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.progress-wrapper {
  width: 100%;
}
.progress-circle {
  position: relative;
}
.progress-circle-info {
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.progress-circle-trail {
  stroke: slategray;
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.dark .progress-circle-trail {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.progress-circle-stroke {
  stroke: currentColor;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.swiper-pagination{
  position: relative !important;
  top: 5px !important;
}
.swiper-pagination-bullet-active{
  background:#504CBC !important ;
}

.accItem>ol{
  list-style: inside decimal;
}

@media only screen and (min-width: 480px){
  .inputStyle {
    width: 3rem !important;
    height: 3rem;
    margin: 0 0.4rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid #808080;
  }
}